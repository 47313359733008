.page-header,
.footer,
.background {
  &--bg-color {
    @each $color, $value in $main-colors {
      &--#{$color} {
        background-color: $value !important;
      }
    }
  }
  &--bg-tints {
    @each $color, $value in $tints-colors {
      &--#{$color} {
        background-color: $value !important;
      }
    }
  }
  &--color {
    @each $color, $value in $main-colors {
      &--#{$color} {
        color: $value !important;
      }
    }
  }
}

.page-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  @include breakpoint(small) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
