.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  flex: 1 1 33%;
  text-decoration: none;
  color: $global-color;
  padding: 2rem 1rem;
  border: 2px solid map-get($tints-colors, gl-red-900);
  border-radius: 1rem;
  transition: transform 0.5s ease-out;
  cursor: pointer;
  &--small {
    background-color: map-get($tints-colors, secondary-100);
  }
  &:hover {
    // transform: scale(1.02);
    background-color: map-get($tints-colors, gl-red-50);
    box-shadow: $global-box-shadow-2;
    .card__image {
      // TODO add transition when removing the cursor
      transform: scale(1.02);
      filter: grayscale(50%);
    }
  }
  &:active {
    transform: scale(95%);
  }

  &__image {
    border-radius: 1rem;
    transition: all 0.5s ease-out;
    height: auto;
    flex-grow: 1;
    width: 100%;
    object-fit: cover;
    &--small {
    }
  }
  &__title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    &--small {
    }
  }
  &__annotation {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    &--small {
    }
  }
}
