.donate {
  &__left,
  &__right {
    padding: 1rem;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      a {
        color: $gl-red;
        &:hover {
          color: map-get($tints-colors, tertiary-300);
        }
      }
    }
    img {
      object-fit: contain;
    }
  }

  &__left {
  }
  &__right {
  }
  &__img {
    display: none;
    @include breakpoint(small) {
      display: block;
    }
  }
  &__annotation {
    padding: 1rem;
    padding-bottom: 0;
  }
}
