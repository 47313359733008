.button {
  align-self: flex-start;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: map-get($main-colors, secondary);
  cursor: pointer;

  &--secondary {
    &:hover {
      background-color: map-get($tints-colors, gl-red-400);
      color: $gl-white;
    }
  }
  &--red {
    &:hover {
      background-color: map-get($tints-colors, primary-300);
      color: $gl-white;
    }
  }
}
