.article {
  &__header {
    &__img-main {
      grid-area: header-main-img;
      width: 100%;
      object-fit: cover;
      max-height: 200px;
      border-radius: 0.5rem;
    }

    &__heading {
      grid-area: header-title;
      text-align: center;
    }
  }
  &__body {
    grid-area: header-body;
  }
}
