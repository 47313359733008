@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Table of Contents:
//
// 1. Global
// 2. Breakpoints
// 3. Shadows

// 1. Global
$primary: hsla(139, 34%, 57%, 1);
$secondary: hsl(52, 93%, 67%, 1);
$tertiary: hsl(213, 67%, 32%, 1);
$eerie-black: hsla(216, 9%, 10%, 1);
$gl-white: hsla(255, 255, 255, 1);
$gl-red: hsla(358, 74%, 60%, 1);

$global-weight-normal: 400;
$global-weight-demi: 600;
$global-weight-bold: 800;

$global-radius: 8px;

$global-color: $eerie-black;
// $global-color-gray: map-get($alpha-colors, secondary-alpha-60);

// 2. Breakpoints
$breakpoints: (
  xsmal: 0,
  small: 480px,
  medium: 724px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
) !default;

h1 {
  font-size: calc(1.5rem + 1.5vw);
}
h2 {
  font-size: calc(1.5rem + 1vw);
}
h3 {
  font-size: calc(1rem + 0.5vw);
}
h3 {
  font-size: calc(1rem + 0.25vw);
}

p {
  line-height: 1.5;
}

// 3. Shadows
$global-box-shadow-1: 0 2px 2px rgba(121, 138, 175, 0.12);
$global-box-shadow-2: 0 2px 2px rgba(121, 138, 175, 0.12),
  0 8px 8px rgba(121, 138, 175, 0.12);
$global-box-shadow-3: 0 2px 2px rgba(121, 138, 175, 0.12),
  0 8px 8px rgba(121, 138, 175, 0.12), 0 18px 18px rgba(121, 138, 175, 0.12);
$global-box-shadow-4: 0 2px 2px rgba(121, 138, 175, 0.12),
  0 8px 8px rgba(121, 138, 175, 0.12), 0 18px 18px rgba(121, 138, 175, 0.12),
  0 32px 32px rgba(121, 138, 175, 0.12);

// 4. Links

a {
  color: $gl-red;
  text-decoration: none;
  word-break: break-word;
  &:hover {
    color: $eerie-black;
  }
}

img {
  width: 100%;
}
