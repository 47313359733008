.margin-left-1 {
  margin-left: 1rem;
}
.margin-right-1 {
  margin-right: 1rem;
}
.margin-top-1 {
  margin-top: 1rem;
}
.margin-bottom-1 {
  margin-bottom: 1rem;
}

.padding-left-1 {
  padding-left: 1rem;
}
.padding-right-1 {
  padding-right: 1rem;
}
.padding-top-1 {
  padding-top: 1rem;
}
.padding-bottom-1 {
  padding-bottom: 1rem;
}

.padding-left-2 {
  padding-left: 2rem;
}
.padding-right-2 {
  padding-right: 2rem;
}
.padding-top-2 {
  padding-top: 2rem;
}
.padding-bottom-2 {
  padding-bottom: 2rem;
}

.padding-left-3 {
  padding-left: 3rem;
}
.padding-right-3 {
  padding-right: 3rem;
}
.padding-top-3 {
  padding-top: 3rem;
}
.padding-bottom-3 {
  padding-bottom: 3rem;
}
