.gallery {
  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 2rem 0;
    gap: 0.5rem;
    @include breakpoint(small) {
      flex-direction: row;
      li {
        flex: 1 1 25%;
      }
    }
  }
  li {
    height: 30vh;
    list-style: none;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.5s ease;
    &:hover {
      filter: grayscale(80%);
    }
  }
}
