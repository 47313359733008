.cards {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 1rem;
  // width: 80%;
  //TODO create cards for small viewport
  @include breakpoint(medium) {
    flex-direction: row;
  }
  &__title {
    color: $gl-white !important;
    &:hover {
      color: $gl-red !important;
    }
  }
}
