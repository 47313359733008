.footer {
  &__navigation {
    grid-area: footer-nav;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    &--link {
      a {
        text-decoration: none;
        color: $eerie-black;

        &:hover {
          color: $gl-red;
        }
        &:active {
          color: $tertiary;
        }
      }
    }
  }

  &__address {
    grid-area: footer-address;
    line-height: 1.5;
    a {
      text-decoration: none;
      color: $gl-red;

      &:hover {
        color: $eerie-black;
      }
      &:active {
        color: $tertiary;
      }
    }
  }

  &__info {
    grid-area: footer-info;
    @include breakpoint(small) {
      text-align: center;
    }
  }

  &__icons {
    grid-area: footer-icons;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logo__footer {
      display: flex;
    }
    .social-icon {
      display: flex;

      padding-top: 1rem;
      &__link {
        @media screen and (max-width: 480px) {
          padding-right: 2rem;
        }
      }
      i {
        color: $gl-red;
        &:hover {
          color: $primary;
        }
      }
    }
    @include breakpoint(small) {
      .logo__footer {
        justify-content: flex-end;
      }
      .social-icon {
        justify-content: flex-end;
      }
      .social-icon__link:nth-child(n + 2) {
        padding-left: 3rem;
      }
    }
  }
}
