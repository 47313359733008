.align {
  &--items {
    &--start {
      align-items: flex-start;
    }
    &--end {
      align-items: flex-end;
    }
    &--center {
      align-items: center;
    }
    &--stretch {
      align-items: stretch;
    }
  }
}

.justify {
  &--content {
    &--start {
      justify-content: flex-start;
    }
    &--end {
      justify-content: flex-end;
    }
    &--center {
      justify-content: center;
    }
    &--space-between {
      justify-content: space-between;
    }
    &--space-around {
      justify-content: space-around;
    }
    &--justify {
      justify-content: justify;
    }
  }
}
