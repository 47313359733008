.partners {
  padding: 1rem 0;
  &__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    vertical-align: middle;
  }
  .img-1 {
    grid-area: img-1;
  }
  .img-2 {
    grid-area: img-2;
  }
  .img-3 {
    grid-area: img-3;
  }
  .img-4 {
    grid-area: img-4;
  }
  .img-5 {
    grid-area: img-5;
  }
  .img-6 {
    grid-area: img-6;
  }
  .img-7 {
    grid-area: img-7;
  }
  .img-8 {
    grid-area: img-8;
    height: 100px;
  }
}
