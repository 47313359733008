.contact {
  // justify-items: center;
  background-color: map-get($alpha-colors, secondary-alpha-20);
  padding: 2rem;
  &__address {
    grid-area: contact-address;
  }
  &__media {
    grid-area: contact-media;
    .social-icon {
      justify-content: flex-start !important;
    }
    a {
      color: $gl-red;
      text-decoration: none;
      &:hover {
        color: $eerie-black;
      }
    }
  }
  &__title {
    grid-area: contact-title;
    color: $tertiary;
  }
}
