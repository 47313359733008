.navbar {
  background-color: $primary;
  // height: 80px;
  position: sticky;
  top: 0;
  z-index: 999;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 1rem;
    height: 80px;
    // @include breakpoint(medium) {
    //   padding-left: 2rem;
    //   padding-right: 2rem;
    // }
    .navbar__logo {
      display: flex;
    }
    .navbar__menu {
      .navbar__list {
        // when navigation is not active it is hidden outside of viewport
        @media screen and (max-width: 723px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          position: absolute;
          top: 80px;
          left: -100%;
          opacity: 1;
          transition: all 0.5s ease;
          list-style-type: none;
        }
        // defined navigation menu when viewport widder than small
        @include breakpoint(medium) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          list-style-type: none;
          height: 100%;
        }
        &.active {
          // when clicked -> active and screen small show navigation
          @media screen and (max-width: 723px) {
            background-color: map-get($tints-colors, gl-red-400);
            left: 0;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 1;
            .navbar__item {
              padding: 1.5rem 0;
              font-size: 1.25rem;
              &#home {
                display: block;
              }
            }

            .navbar__link {
              // dopisat

              svg {
                display: inline-block;
                vertical-align: sub;
                // padding-right: 1rem;
              }
            }
          }
        }
      }
      .navbar__item {
        padding: 1rem;
        &:hover {
          &::after {
            content: "";
            border-bottom: 2px solid $gl-white;
            display: block;
            transition: all 0.2s ease-out;
          }
        }
        &#home {
          display: none;
        }
      }

      .navbar__link {
        color: $gl-white;
        text-decoration: none;
        svg {
          display: none;
        }
      }
    }
  }

  .navbar__button {
    display: block;
    color: $gl-white;
    & > i {
      font-size: 1.5rem;
    }

    @include breakpoint(medium) {
      display: none;
    }
  }
}
