.hero-section {
  &__left {
    grid-area: hero-section-left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    p {
      color: $gl-white;
    }
  }
  &__right {
    grid-area: hero-section-right;
  }
  &__title {
    line-height: 1.5;
    color: $gl-white;
    text-align: center;
    span {
      color: $tertiary;
    }
    @include breakpoint(small) {
      text-align: left;
    }
  }
}
