.layout {
  width: 100%;
  flex-grow: 1;
  &__inner {
    max-width: 50rem;
    margin: 0 auto;
    padding: 2rem 1rem;
    @include breakpoint(xxlarge) {
      width: 50rem;
    }
  }
  &__project {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem 2rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }
  &__article {
    display: grid;
    gap: 2rem;
    @media (max-width: 724px) {
      grid-template-areas:
        "header-title"
        "header-main-img"
        "header-body";
    }
    @include breakpoint(medium) {
      grid-template-areas:
        "header-main-img "
        "header-title "
        "header-body ";
    }
  }
  &__cards {
    padding: 2rem 0;
    background-color: map-get($main-colors, tertiary);
  }
  &__navigation {
    padding: 0 1rem;
  }
  &__footer {
    display: grid;
    padding: 2rem;
    gap: 1rem;
    grid-template-areas:
      "footer-nav"
      "footer-address"
      "footer-icons"
      "footer-info";
    @include breakpoint(small) {
      grid-template-areas:
        "footer-nav footer-address ... footer-icons"
        "footer-info footer-info footer-info footer-info";
    }
  }

  &__section {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 1rem;
    grid-template-areas:
      "section-left"
      "section-right";
    @include breakpoint(small) {
      grid-template-areas: "section-left section-right";
      gap: 2rem;
      &--reverse {
        grid-template-areas: "section-right section-left";
      }
    }
  }
  &__donate {
    @extend .layout__section;
    @media (max-width: 480px) {
      gap: 0;
    }
  }
  &__contact {
    display: grid;
    grid-auto-columns: 1fr;

    gap: 1rem;
    grid-template-areas:
      "contact-title"
      "contact-address"
      "contact-media";
    @include breakpoint(small) {
      grid-template-areas:
        "contact-title contact-title"
        "contact-address contact-media";
      // gap: 2rem;
    }
  }
  &__partners {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    align-items: center;
    gap: 1rem;
    grid-template-areas:
      "img-1"
      "img-2"
      "img-3"
      "img-4"
      "img-5"
      "img-6"
      "img-7"
      "img-8";
    @include breakpoint(small) {
      gap: 2rem;
      grid-template-areas:
        "img-2 img-2 img-3 img-3 img-1 img-1 img-4 img-4"
        "img-5 img-5 img-6 img-6 img-7 img-7 img-8 img-8";
    }
  }
  &__hero-section {
    display: grid;
    grid-auto-columns: 1fr;

    gap: 1rem;
    grid-template-areas:
      "hero-section-left"
      "hero-section-right";
    @include breakpoint(small) {
      grid-template-areas: "hero-section-left hero-section-right";
      grid-auto-columns: 1fr;
      gap: 2rem;
    }
  }
}
