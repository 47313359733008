.about {
  &__header {
  }
  &__img {
    width: 100%;
    object-fit: cover;
    max-height: calc(100px + 10vw);
  }
  &__description {
    // padding: 1rem 0;
  }
}
