$text-size: (
  small: 0.625rem,
  caption: 0.8rem,
  medium: 1.25rem,
  large: 1.5rem,
  xlarge: 2rem,
  xxlarge: 2.5rem,
  huge: 3rem,
  mastodont: 4rem,
);

.text {
  &--size {
    @each $size, $value in $text-size {
      &--#{$size} {
        font-size: $value !important;
      }
    }
  }
}
