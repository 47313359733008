.section {
  &__left {
    grid-area: section-left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }
  &__right {
    grid-area: section-right;
    display: none;
    @include breakpoint(small) {
      display: inline-block;
    }
  }
  &__img {
    max-width: 100%;
    max-height: calc(15rem + 10vw);
    object-fit: contain;
  }

  &__label {
  }
}
