.logo-icon {
  &--color {
    @each $color, $value in $main-colors {
      &--#{$color} {
        path {
          fill: $value !important;
        }
      }
    }
  }
  &.big {
    svg {
      width: calc(50px + 3vw);
      height: calc(50px + 3vw);
    }
  }
}
